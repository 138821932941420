import {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import { Box, Collapse, IconButton, LinearProgress, Paper,
    Table, TableBody, TableCell, TableContainer, TableHead,
    TableRow, Tooltip, } from '@material-ui/core';
import qs from 'qs';
import moment from 'moment';
import {ErrorGeneral, llamadaApiToken} from '../funciones/ClienteAxios';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import LocationOnIcon from "@material-ui/icons/LocationOn";
 
import CreateIcon from '@material-ui/icons/Create';
import ModalEdit from './ModalEdit'
const useStyles = makeStyles(theme=>({
    container: {
        maxHeight: window.innerHeight - 170,
        minHeight: 280,
        minWidth: '100%',
    },
    container2: {
        maxHeight: 550,
    },
    tableRow: {
        "&$selected, &$selected:hover": {
            backgroundColor: theme.palette.secondary.main
        }
    },
    tableCell: {
        "$selected &": {
            color: "white"
        }
    },
    hover: {},
    selected: {}
}));

export default function TablaRecorridos({ history, fechasSeleccionadas, selectedID, setSelectedID,
                                            source, idusu, buscar, checked,  
                                            setRecorrido,buscarDatos, listBecas
                                        }) {
    const classes = useStyles();
    const [espera, setEspera] = useState(false);
    const [datos, guardarDatos] = useState([])
    const [sinDatos, guardarSinDatos] = useState(true)
    const [modEdit, setModEdit] = useState(false)
    const [open, setOpen] = useState(false);
    const [seleccionado, setSeleccionado] = useState([]);
    let fInicio = moment(fechasSeleccionadas.inicio).format("YYYY-MM-DD")
    let fFinal = moment(fechasSeleccionadas.final).format("YYYY-MM-DD")
    let filter = 0


    useEffect(() => {        
        guardarDatos(listBecas)
        //llenarTabla();
    }, [buscar]);

    const llenarTabla = () => {
        setEspera(true)
        if (checked) {
            filter = idusu
        } else {
            filter = 0
        }

        const data = qs.stringify({
            Usuario: idusu,            
            Fini: fInicio + 'T00:00:00',
            Ffin: fFinal + 'T23:59:59',
            idDispositivo: '45vf2d1vdf21d2f'
        });
        const url = "recorrido/v3/list";

        function response(data) {
            if (data[0].Id !== -1 && data[0].Id !== 0) {
                guardarSinDatos(true)
                guardarDatos(data)
                setEspera(false)
            } else {
                guardarSinDatos(false)
                setEspera(false)
            }
        }

        llamadaApiToken(data, url, response, ErrorGeneral, history, source);
    };

    const seleccionar = (auxId, auxOrden) => {
        setSelectedID(auxId)
        setRecorrido(auxOrden)
        setSeleccionado(auxOrden)
    }
 
    const abrirModalEdit = () => {
        setModEdit(true)
    }

    function verMas(auxId) {
        setSelectedID(auxId)
        if (auxId === selectedID) {
            setOpen(!open)
        } else {
            setOpen(false)
        }
    }

    function Row(props) {
        const {row} = props;
        return (
        <>
            <TableRow onClick={() => { seleccionar(row.Id, row) }}
                className={classes.tableRow} selected={selectedID === row.Id}
                classes={{hover: classes.hover, selected: classes.selected}}
            >                                       
                {/* <TableCell align="center" padding={'none'} style={{width: 30}}>
                    {row.Obsrv.length !== 0 ?
                        <Tooltip title={open && selectedID === row.Id ? 'Ver menos' : 'Ver más'}>
                            <IconButton color="primary" aria-label="expand row" size="small"
                                onClick={() => verMas(row.Id)}>
                                {open && selectedID === row.Id ?
                                    (selectedID === row.Id ?
                                        <KeyboardArrowUpIcon style={{color: 'white'}}/>
                                        : <KeyboardArrowUpIcon/>)
                                    : (selectedID === row.Id ?
                                        <KeyboardArrowDownIcon style={{color: 'white'}}/>
                                        : <KeyboardArrowDownIcon/>)}
                            </IconButton>
                        </Tooltip>
                        : null}
                </TableCell> */}
 
                <TableCell align="center" padding={'none'} style={{width: 30}}>
                    <Tooltip title={'Editar'}>
                        <IconButton color="primary" size="small"
                            onClick={() => abrirModalEdit()}>
                            <CreateIcon style={{color:selectedID === row.Id? "white":"" }} color={selectedID === row.Id? "inherit":"primary" } />  
                        </IconButton>
                    </Tooltip>
                </TableCell> 
                <TableCell className={classes.tableCell} align="center" padding={'none'} width={75}
                    style={{fontSize: 12 + 'px'}} >
                    {moment.utc(row.FReg).local(true).format("DD/MM/YYYY HH:mm")}
                </TableCell>
                <TableCell className={classes.tableCell} align="center" padding={'none'} width={75}
                    style={{fontSize: 12 + 'px'}} >
                    {moment.utc(row.FEnv).local(true).format("DD/MM/YYYY HH:mm")}
                </TableCell>
                <TableCell className={classes.tableCell} align="left" padding={'none'} width={180}
                    style={{fontSize: 12 + 'px'}} >
                    {row.MunNom}
                </TableCell>
                <TableCell className={classes.tableCell} align="center" padding={'none'} width={120} >
                    {row.NivelNom}
                </TableCell>
                
                <Tooltip title={row.P3Nom}>
                    <TableCell className={classes.tableCell} align="center" padding={'none'} width={80}
                        style={{fontSize: 12 + 'px'}} >
                        {row.Escuela}
                    </TableCell>
                </Tooltip>
                <Tooltip title={row.P2Nom}>
                    <TableCell className={classes.tableCell} align="center" padding={'none'} width={80}
                        style={{fontSize: 12 + 'px'}} >
                        {row.Edad}
                    </TableCell>
                </Tooltip>
                <Tooltip title={row.P1Nom}>
                    <TableCell className={classes.tableCell} align="center" padding={'none'} width={80}
                        style={{fontSize: 12 + 'px'}} >
                        {`${row.Nombre} ${row.Paterno} ${row.Materno}`}
                    </TableCell>
                </Tooltip>
            
           {/*      <TableCell className={classes.tableCell} align="center" padding={'none'} width={80}
                    style={{fontSize: 12 + 'px'}} >
                    {row.EdoNom}
                </TableCell>
                <TableCell className={classes.tableCell} align="center" padding={'none'} width={100}
                    style={{fontSize: 10 + 'px'}} >
                    {row.MuniNom}
                </TableCell> */}
                <TableCell className={classes.tableCell} align="center" padding={'none'} width={55} >
                    {row.Tel}
                </TableCell>
                <TableCell className={classes.tableCell} align="left" padding={'none'} style={{fontSize: 12 + 'px'}}
                     width={220}>
                    {row.Dir}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={9}>
                    <Collapse in={open && selectedID === row.Id && row.Obsrv.length !== 0} timeout="auto"
                        unmountOnExit>
                        <Box margin={1}>
                        <Table size="small" aria-label="purchases">
                            <TableHead>
                                <TableRow>
                                    <TableCell padding={'none'}> Observación </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow key={row.Id}>
                                    <TableCell padding={'none'}> {row.Obsrv}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
        );
    }

    function tablaCompleta(auxlista) {
        return (
        <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
            <TableHead>
                <TableRow>
                    
                    <TableCell align="center" padding='none'>{auxlista.length}</TableCell>                            
                    <TableCell align="center" padding='none'>Fecha Sistema</TableCell>
                    <TableCell align="center" padding='none'>Fecha Registro</TableCell>
                    <TableCell align="center" padding='none'>Municipio</TableCell>
                    <TableCell align="center" padding='none'>Tipo</TableCell>
                    <TableCell align="center" padding='none'>Escuela</TableCell>
                    <TableCell align="center" padding='none'>Edad</TableCell>
                    <TableCell align="center" padding='none'>Nombre</TableCell>
                    
                    <TableCell align="center" padding='none'>Teléfono</TableCell>
                    
                    <TableCell align="center" padding='none'>Dirección</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {auxlista.length != 0 ? (auxlista.map((row) => (
                    <Row key={row.Id} row={row}/>
                ))) : null}
            </TableBody>
        </Table>
        </TableContainer>
        )
    }

    const tablaSinDatos = () => {
        return (
            <TableContainer className={classes.container2}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" padding='none'></TableCell>
                            <TableCell align="center" padding='none'>Fecha Sistema</TableCell>
                            <TableCell align="center" padding='none'>Fecha Registro</TableCell>
                            <TableCell align="center" padding='none'>Nombre</TableCell>
                            <TableCell align="center" padding='none'>Tipo</TableCell>
                            <TableCell align="center" padding='none'>Estado</TableCell>
                            <TableCell align="center" padding='none'>Municipio</TableCell>
                            <TableCell align="center" padding='none'>Sección</TableCell>
                            <TableCell align="center" padding='none'>Dirección</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow className={classes.tableRow}>
                            <TableCell className={classes.tableCell} align="center">Dia</TableCell>
                            <TableCell className={classes.tableCell} align="left">Sin</TableCell>
                            <TableCell className={classes.tableCell} align="left">Recorridos</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }
     
    
    const modalEditar= modEdit ? <ModalEdit abrirModal={modEdit} setAbrirModal={setModEdit}
                                    idusu={idusu} history={history} source={source} latitud={0.0}
                                    longitud={0.0} actualiza={buscarDatos} registro={seleccionado}
                                /> : null

    const contenido = sinDatos && datos.length != 0 ? tablaCompleta(datos) : tablaSinDatos()
    const tabla = espera ? (<Box mt={2}><LinearProgress/> </Box>) : (contenido)

    return (
    <Paper>
        {modalEditar}
        {tabla}
      
    </Paper>
    );
}