import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Box,Collapse,IconButton,Paper,Table,TableBody,TableCell,LinearProgress,
        TableContainer,TableHead,TableRow,} from '@material-ui/core';
import axios from 'axios';
import qs from 'qs';
import moment from 'moment';
import { useHistory } from "react-router-dom";
import {exportarArch} from '../funciones/ListaExcel'
import SaveIcon from '@material-ui/icons/Save';
import CreateIcon from '@material-ui/icons/Create';
 
import {findNumber} from "../funciones/FindNumber";
const useStyles = makeStyles({
  root: {
    width: '21rem',
  },
  container: {
    //maxHeight: 550,
    maxHeight: window.innerHeight-220,
    minHeight: 280,
    maxWidth: '21rem',
   
  },
  container2: {
    maxHeight: 600,
    maxWidth: '21rem',
    //maxHeight: window.innerHeight-170,
    //minHeight: 280,
  },
  tableRow: {
    "&$selected, &$selected:hover": {
      backgroundColor: "#51ad32"
    }
  },
  tableCell: {
    "$selected &": {
      color: "white"
    }
  },
  hover: {},
  selected: {}
});

export default function TablaBitacora({   lista}) {
  const classes = useStyles();
  let history = useHistory();
  const source = axios.CancelToken.source();    
  const [listaExcel, setListaExcel] = useState([]);
  const [selectedID, setSelectedID] = useState(null);
  //const [open, setOpen] = React.useState(false);     
  const [espera,setEspera]=useState(false);
  const [datos, setDatos] = useState([])      
  React.useEffect(()=>{ 
    let listExc=[]
    listExc.push(["Fecha", "Disponible", "Tiempo", "Cobro", "Exento", "Paros"])
    if (lista.length !== 0 ) {
      
      lista.forEach(regis => {
        listExc.push([moment.utc(regis.fecha).local(true).format("DD/MM/YYYY"), 
          `${regis.disponible}${regis.disponible===100 ? ".00":""}%`,  
          regis.totalTiempo, regis.cobro, regis.exento, regis.paros])  
      });
      
    }
    else{
      listExc.push(["", "Dia", "Sin", "Registros", "", ""])
    }
    setListaExcel(listExc)
    setDatos(lista)
    
    return ()=> {
      source.cancel();
    }                

  },[ lista]); 

  const seleccionado=(auxId,)=>{
    setSelectedID(auxId)        
  }

  const celda = (alinear, estilo, contenido) => {
    return (
    <TableCell
      align={alinear} className={classes.tableCell}
      padding={"none"} style={estilo} 
    >
      {contenido}
    </TableCell>
    );
  };

function Row(props) {
  const { row } = props;
  
  return (
    <React.Fragment>
      <TableRow onClick={ () => {  } }
        className={classes.tableRow} selected={ selectedID === row.Id}
        classes={{ hover: classes.hover, selected: classes.selected }}        
      >                
        {celda("center",{width:65+'px',fontSize:"12px"},moment.utc(row.fecha).local(true).format("DD/MM/YYYY"))}                           
        {celda("right",{width:30+'px',fontSize:"12px"},`${row.disponible}${row.disponible===100 ? ".00":""}%`)}    
        {celda("right",{width:30+'px',fontSize:"12px"},row.totalTiempo)}      
        {celda("right",{width:'50px',fontSize:"12px"},row.cobro)}     
        {celda("right",{width:'50px',fontSize:"12px"},row.exento)}    
        {celda("right",{width:'50px',fontSize:"12px",paddingRight:"8px"},row.paros)}     
      </TableRow>
      <TableRow>        
      </TableRow>
    </React.Fragment>
    );
  }

  function tablaCompleta(auxlista){
    //console.log("entrando sin preguntar");
    return(
    <TableContainer className={classes.container}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>            
            <TableCell align="center" padding='none' style={{fontSize:"12px"}} >
              {findNumber('|62|') ?
                <IconButton onClick={()=>exportarArch(listaExcel," Lista de Paros")} 
                  disabled={ datos.length===0?true:false } size='small'>            
                  <SaveIcon   color={datos.length===0?"inherit":"primary"}   />            
                </IconButton>  
              : null}   
            Fecha</TableCell>
            <TableCell align="center" padding='none' style={{fontSize:"12px"}}>Disponible</TableCell>               
            <TableCell align="center" padding='none' style={{fontSize:"12px"}}>Tiempo</TableCell>                          
            <TableCell align="center" padding='none' style={{fontSize:"12px"}}>Cobro</TableCell>                    
            <TableCell align="center" padding='none' style={{fontSize:"12px"}}>Exento</TableCell>
            <TableCell align="center" padding='none' style={{fontSize:"12px"}}>Paros</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {auxlista.length!=0 ? (auxlista.map((row) => (
              <Row key={row.Id} row={row} />
          ))):null}
        </TableBody>
      </Table>
    </TableContainer>
   )
  }

  const tablaSinDatos=()=>{
   return(<TableContainer className={classes.container2}>
    <Table stickyHeader aria-label="sticky table">
      <TableHead>
        <TableRow>            
          <TableCell align="center"> </TableCell>            
          <TableCell align="left">Fecha</TableCell>
          <TableCell align="left">Estatus</TableCell>                                           
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow className={classes.tableRow} >          
          <TableCell  className={classes.tableCell} align="center">Dia</TableCell>          
          <TableCell  className={classes.tableCell} align="left">Sin</TableCell>             
          <TableCell  className={classes.tableCell} align="left">Registros</TableCell>                      
        </TableRow>
      </TableBody>
    </Table>
    </TableContainer>
    ) 
  }

  
 
  const contenido2=tablaCompleta(datos) 
  const tabla = datos.length!== 0 ?contenido2:tablaSinDatos()
  //const tabla = espera|| cargaEdit ?  (<Box><LinearProgress/> </Box>) :( contenido )
 
  return (
    <Paper className={classes.root}>
      {tabla}
    </Paper>
  );
}