import { useEffect, useState, useRef } from "react";
import {  Card,  FormControl,  FormControlLabel,  Box, FormLabel,
  Grid,  IconButton,  Radio,  RadioGroup,  TextField,  Typography,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { v4 as uuidv4 } from 'uuid';
import {toast} from "react-toastify";
const useStyles = makeStyles((theme) => ({
  statusCard: {
    textAlign: "center",
    color: "white",
    marginRight: 5, //padding: '24px 12px',
    height: "100%",
    paddingLeft: 0.5 + "rem",
    paddingRight: 0.5 + "rem",
  },
  marginBottom: {
    marginBottom: theme.spacing(1),
  },
}));

const CardDet = ({  element,  index,   }) => {
  const classes = useStyles();
  const inputRef = useRef(null);
  const acceptFile="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"  
  const [available, setAvailable] = useState(false);    
  const [espera, setEspera] = useState(false);
  const [arraySubidos, setArraySubidos] = useState([])
  const [arrayPreview, setArrayPreview] = useState([])
  const [actividad, setActividad] = useState({  });
 

  useEffect(() => {
    const source = axios.CancelToken.source();

    return () => {
      source.cancel();
    };
  }, [index]);


  const handleChange = function (event) {
    const regex = /([a-zA-Z0-9\s_.\-():])+(.pdf)$/
    let image = event.target.files[0]
    let auxCode = uuidv4();
    if (image && image.name.toLowerCase().match(regex)) {
      let auxNombre = auxCode + image.name.slice(image.name.lastIndexOf("."), image.name.length);
      let myNewFile = new File([image], auxNombre, {type: image.type});
      setArraySubidos([...arraySubidos, myNewFile])
      setArrayPreview({name: image.name})
    } else {
      let nombre = "";
      if (image.name.length >= 25) 
      { nombre = image.name.substring(0, 24) + "..." + image.name.substring(image.name.lastIndexOf("."), image.name.length);
      } 
      else 
      { nombre = image.name;
      }
      let mensaje = `Formato de archivo incorrecto en ${nombre} seleccione una imagen tipo pdf.`
      toast.warning(mensaje, {
        pauseOnFocusLoss: false,
        theme: "colored",
        toastId: `incorrecto-sel`
      });
    }
  }


  const btnCancelar = () => {
 
    setAvailable(false);
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };

  const btnSubir=()=>{
    return(
    <Grid>
      <form id="form-file-upload">
        <input ref={inputRef} type="file" accept={acceptFile} id="input-file-upload" 
          onChange={handleChange}/>
        <label id="label-file-upload" htmlFor="input-file-upload" className={ ""}>                                    
          <IconButton  //disabled={espera ? true : false} 
            onClick={onButtonClick}>
            <CloudUploadIcon/>
          </IconButton>                        
        </label>
      </form>    
    </Grid>
    )
  }

  const tamMax =80

  return (
    <div key={index} style={{ overflowY: "auto" }}>
      <Card
        className={classes.marginBottom}
        style={{ padding: 0, height: tamMax, }}
      >
        <Grid container alignItems={"center"} justifyContent={"center"}>
          <Grid item>
            <Card
              className={classes.statusCard}
              style={{ backgroundColor: "#049b4f",
                height: tamMax, alignItems: "center", display: "flex",
              }}
            >
              <Typography component="p" variant="h6">
                Pendiente                                  
              </Typography>
            </Card>
          </Grid> 
   
          
          <Grid item>
            <Typography component="p" variant="h6" style={{fontSize:"4rem"}}>
              {element.TipoNom}                                  
            </Typography>
          </Grid>          
          <Grid item style={{ marginLeft: "auto" }}>
            <Grid container>
              {!espera ? (
                available ? (
                  <>
                    <Grid item>
                      <IconButton
                        size={"small"}
                        aria-label="delete"
                        onClick={(e) => btnCancelar()}
                      >
                        <CancelIcon size="small" color={"error"} />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <IconButton
                        size={"small"}
                        aria-label="update"
                        onClick={()=>{}}
                      >
                        <CheckCircleIcon
                          size="small"
                          style={{ color: "#0ac15d" }}
                        />
                      </IconButton>
                    </Grid>
                  </>
                ) : null
              ) : (
                <Box mt={1} mr={1} ml={0.5}>
                  <CircularProgress size={20} />
                </Box>
              )}
            </Grid>
            <Grid  >
              
              {arrayPreview.length !== 0 ?
              <Typography>
                {arrayPreview.name}
              </Typography>
              
              :null
              }
            </Grid>
            {<Grid  >         
              <input ref={inputRef} type="file" accept={acceptFile} id="input-file-upload" 
                onChange={handleChange} hidden/>
                                          
                <IconButton  id="label-file-upload" htmlFor="input-file-upload" className={ ""} //disabled={espera ? true : false} 
                  onClick={onButtonClick}>
                  <CloudUploadIcon/>
                </IconButton>                                               
            </Grid>}
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

export default CardDet;
