import React from 'react';
import {ThemeProvider} from '@material-ui/core/styles'
import Tema from './components/layout/MisTemas';
import {BrowserRouter as Router,Switch,Route,Redirect} from 'react-router-dom';
import Contenedor from './components/layout/Contenedor'
import ParosFallas from './components/ParosFallas/ParosFallasinicio'
import Login from './components/auth/Login'
import AuthProvider from './components/context/AuthContext'
import OrdenMantenimiento from './components/ReporteMantenimiento/RepoInicio'
import GeneralesProvider from './components/context/GeneralesContext'
import DirectDrive from './components/DirectDrive/DirectDriveInicio'
import RepoBitacora from './components/Reportes/RepoBitacora'
import RepoDirectD from './components/Reportes/RepoDirectDrive'
import RepoMantenimiento from './components/Reportes/RepoMantenimiento'
//import RepoActividades from './components/Reportes/RepoActividades'
import EdificioMantenimiento from './components/Edificios'
import CatalogoEnerInicio from "./components/CatalogoEnergia/CatalogoEnerInicio";
import ReporteOrdenActividad from './components/Reportes/RepoOrdenActividad'
//import MttoCabinaN from './components/MttoCabina/CabinaInicio'
import MttoCabina from './components/MttoCabina2'
import CarroTensor from './components/CarroTensor/CarroTensorInicio'
import Refacciones from './components/Refacciones/Refacciones'
import PilonaInicio from './components/Pilonas';
import CatalgoParoFallas from './components/CatalogoParoFallas/CatalogoParoFallas'
import BitacoraCCTV from './components/CCTV';
import { ToastContainer } from 'react-toastify';
import EliminacionEdificio from './components/ConsultaEliminacionEdificio';
import EliminaEvidencia from './components/ConsultaEliminacionEvidencias'
import ReporteMttoExcel from './components/OTMttoExcel';
import RepoCCTV from './components/Reportes/RepoCCTV';
import Usuarios from './components/Usuarios';
import {findNumber} from './components/funciones/FindNumber'
import VentanaVacia from './components/ventanaVacia'
import Monitoreo from './components/Monitoreo';
import RepoMonit from './components/Reportes/RepoCCTV_monit';
import PaginaNo from './components/PaginaNo';
import RepoBitacoraRango from './components/Reportes/RepoBitacoraRango';
import Becas from './components/Becas'
import 'react-toastify/dist/ReactToastify.css';
function App() {
  const nvl = localStorage.getItem('prueba');
  const CerrarSesion = () => {
    localStorage.clear();
    return (
    <Switch>
      <Redirect to="/"/>
    </Switch>)
  }
  
  const CerrarSesion2 = () => {
   // localStorage.clear();
    return (
    <Switch>
      <Redirect to="/vacio"/>
    </Switch>)
  }

  return (
    <AuthProvider>     
      <GeneralesProvider >
      <Router>
        <ThemeProvider theme={Tema}>        
          <Contenedor>
            <Switch>
              <Route exact path="/" component={Login}  />
              <Route exact path="/paros-fallas" component={ ParosFallas}  />
              <Route exact path="/vacio" component={VentanaVacia}  />
              <Route exact path="/orden-trabajo" component={OrdenMantenimiento}  />
              <Route exact path="/mtto-cabina" component={MttoCabina}  />
             {/*  <Route exact path="/mtto-cabina-n" component={MttoCabinaN}  /> */}
              <Route exact path="/becas" component={Becas}  />
              <Route exact path="/mtto-pilona" component={PilonaInicio}  />
              <Route exact path="/direct-drive" component={DirectDrive}  />            
              <Route exact path="/bitacora-cctv" component={BitacoraCCTV}  />                            
              <Route exact path="/monitoreo-cctv" component={Monitoreo}  />
              <Route exact path="/edificio-mantenimiento" component={EdificioMantenimiento}  />               
              <Route exact path='/carro-tensor' component={CarroTensor}/> 
              <Route exact path='/catalogo-energia' component={CatalogoEnerInicio}/> 
              <Route exact path='/catalogo-refacciones' component={Refacciones}/> 
              <Route exact path='/catalogo-paro-solucion' component={CatalgoParoFallas}/>               
              <Route exact path='/catalogo-usuarios' component={Usuarios}/>               
              <Route exact path='/elimina-evidencia' component={EliminaEvidencia}/> 
              <Route exact path='/elimina-evidencia-edificio' component={EliminacionEdificio}/> 
              <Route exact path="/reporte-bitacora" component={RepoBitacora}  /> 
              <Route exact path="/reporte-bitacora-rango" component={RepoBitacoraRango}  /> 
              <Route exact path="/reporte-direct-drive" component={RepoDirectD}  /> 
              <Route exact path="/reporte-mtto-edificio" component={RepoMantenimiento}  />              
              <Route exact path="/reporte-orden-actividad" component={ReporteOrdenActividad}  /> 
              <Route exact path="/reporte-cctv" component={RepoCCTV}  /> 
              <Route exact path="/reporte-monitoreo" component={RepoMonit}  /> 
              <Route exact path="/reporte-mtto-excel" component={ReporteMttoExcel}  />            
              <Route exact
                path="/reporte-mtto-excel2">{nvl=== '4' ? ReporteMttoExcel : CerrarSesion}</Route>
              <Route path="*" component={  PaginaNo } />
            </Switch>
          </Contenedor>
        </ThemeProvider>
        <ToastContainer autoClose={5000} closeOnClick/>
      </Router>    
      </GeneralesProvider>
    </AuthProvider>
  );
}

export default App;
