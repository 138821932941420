import React,{useEffect,useContext,useState,useRef} from 'react'
import {AuthContext} from '../context/AuthContext'
import {GeneralesContext} from '../context/GeneralesContext'
import { Box,Tooltip,IconButton,FormControl, Radio, RadioGroup,
  FormControlLabel, Typography, Card, Checkbox  } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { Bar, Doughnut} from 'react-chartjs-2';
import Reporte from './Reporte'
import ReporteSN from './ReporteSNDatos'
import moment from 'moment';
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import Reloj from './Reloj';
import LinearProgress from '@material-ui/core/LinearProgress';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import esLocale from "date-fns/locale/es";
import { DatePicker,MuiPickersUtilsProvider} from '@material-ui/pickers';
import {findNumber} from '../funciones/FindNumber'
import {llamadaApiToken,ErrorGeneral , llamaApiCargaToken } from '../funciones/ClienteAxios';
import Disponib from '../Disponi'
import TablaBitacora from '../Bitacora-Reporte/TablaBitacora'
import TablaEfectividad from '../Bitacora-Reporte/TablaEfectividad';
import SearchIcon from '@material-ui/icons/Search';
import NumberFormat from 'react-number-format';
const useStyles = makeStyles((theme)=>({

  title: {
    fontSize: 30,
    fontWeight:650,
  },
  title2: {
      fontSize: 22,
      fontWeight:650,
    },
  title4: {
  fontSize: 18,
  fontWeight:650,
  },
  title3: {
  fontSize: 16,
  fontWeight:650,
  },
  
 
  
}));

const Relojes2 = ({fechas,setFechas,opcionBit}) => {    
  const {inicio,final}=fechas  
  const guardarInicio = auxF =>
  {
    setFechas({...fechas, inicio : auxF })
  };  
  
  const guardarFinal = auxF =>
  {
    setFechas({...fechas, final : auxF })
  };

  return (
  <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>  
  <Box display={"flex"} flexDirection={"column"}> 
    <Box component="span"  >
      <Tooltip title="Seleccionar Fecha" >                
        <DatePicker
          disableFuture   format="dd/MM/yyyy"
          views={["year", "month", "date"]}
          label="Fecha" size="small"
          minDate="2023-03-31" maxDate={new Date()}
          value={inicio} onChange={guardarInicio}            
          style={{width:7+'rem'}} cancelLabel={"Cancelar"}                  
          okLabel="Seleccionar" 
        />                           
      </Tooltip>                                   
    </Box>  
   {opcionBit!=="dia"?
    <Box   component="span" width="25%">
      <DatePicker
        disableFuture id="final"
        format="dd/MM/yyyy" label="Fecha Final"
        views={["year", "month", "date"]}
        minDate={inicio} maxDate={new Date()}
        value={final} name="final"
        size="small" style={{width:6.5+'rem'}}      
        cancelLabel={"Cancelar"} okLabel="Seleccionar"    
        onChange={guardarFinal}
        minDateMessage={"La fecha final debe ser menor a Fecha inicial"}
      />
    </Box>
    :null}                  
    </Box> 
  </MuiPickersUtilsProvider>
  );
}

const RadioBitacora = ({opcion, setOpcion,opcionRef}) => {
  
  const handleRadioChange = (event) => {
    console.log(event.target.value);
    setOpcion(event.target.value);    
  };

  const handleRadioChange2 = (event) => {
    console.log(event.target.value);
    opcionRef.current=event.target.value;    
  };
  return (  
  <FormControl component="fieldset">    
    <RadioGroup row aria-label="position" name="opcion" defaultValue="top"
      onChange={handleRadioChange} value={opcion}
    > <FormControlLabel
        value="dia"  control={<Radio color="primary" />}
        label="Día" //labelPlacement="top"
      />
      <FormControlLabel
        value="rango"  control={<Radio color="primary" />}
        label="Rango" //labelPlacement="value"
      />                  
     
             
      
    </RadioGroup>
  </FormControl>      
  )
}
const colores=["#5DE163","#FFC400","#24392D","#018786","#03DAC5","#E91E63"]
const camposListas=[ 'Total Usuarios', 'Usuarios', '3Edad', 'Discapacidad', 'Niños','Otros',  ]

const RepoBitacoraRango = () => {
  const usu= localStorage.getItem('UsuarioNom');
  const nvl= localStorage.getItem('Tipo');    
  const usuID= localStorage.getItem('UsuId'); 
  const history = useHistory();
  const classes = useStyles(); 
  const [arrGrafica1, setArrGrafica1] = useState([])  
  const [arrGrafTp, setArrGrafTp] = useState([])  
  const [checkDetalle, setCheckDetalle] = useState(false)            
  const source = axios.CancelToken.source();  
  const [datos,setDatos]=useState([])
  const [listDet,setListDet]=useState([])
  const [espera,setEspera]=useState(false)
  const [espera2,setEspera2]=useState(false)
  const [espera3,setEspera3]=useState(false)
  const {usuario,guardarUsuario}=useContext(AuthContext);
  const {guardarGenerales}=useContext(GeneralesContext);  
  const [listDispo,setListDispo]=useState([])
  const [listEfectividad,setListEfectividad]=useState([])
  const [buscar,setBuscar]=useState(false)
  const [fechas,setFechas]=useState({inicio:new Date(),final:new Date()})
  const [opcionBit, setOpcionBit] = useState('rango');
  const opcionRef = useRef('dia');
  const {inicio,final}=fechas
  
  useEffect(()=>{               
   
    veriSesion()
    datosBitacora()
    getListEfectividad()
   // datosDispo()
    if (opcionBit==='dia') 
    {
      datosDispo()
    }
    else{
      datosDispoRango()
    }

    return ()=> {
      source.cancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps	
  },[buscar])

  useEffect(()=>{
    setListDispo([])
  },[opcionBit])

  const veriSesion =  () => 
  {            
    if(usu===null)
    {                                
      guardarUsuario([])						
      guardarGenerales([])     
      localStorage.clear();           
      history.push("/")	
    }
    else
    { 
      if (usuario.length === 0) 
      {
        guardarUsuario({Nombre:usu,nivel:nvl});                                      
      }
      guardarGenerales({Titulo:'Reporte Bitácora'})                     
    }
  }

  const getListEfectividad=()=>{
     
    const fInicio=moment(inicio).format("YYYY")   
    const fFin=moment(final).format("YYYY")                
    const data = qs.stringify({    
      Usuario:usuID,
      Anio:opcionBit==='rango'? fFin: fInicio ,                               
      idDispositivo:'ifrekvjkxcdwdcwe'
    });
    const url = "/reporte/bitacora/efectividad";
    function respuesta(aux) {
      if (aux[0].length!==0) 
      {                
        setListEfectividad(aux);                                       
      }          
    }

    llamaApiCargaToken(data, url,respuesta,ErrorGeneral,setEspera3,history,source);
  }
  
  const datosBitacora=()=>{
     
    const fInicio=moment(inicio).format("YYYY-MM-DD")   
    const fFin=moment(final).format("YYYY-MM-DD")                
    const data = qs.stringify({    
      Usuario:usuID,
      fechaIni:fInicio+'T00:00:00' ,
      fechaFin:(opcionBit==='dia'?fInicio: fFin)+'T23:59:59',                                  
      idDispositivo:'ifrekvjkxcdwdcwe'
    });
    const url =opcionBit==='dia'? "/reporte/bitacora/v2/rango":"/reporte/bitacora/v2/rango/full";
    function respuesta(aux) {
      if (aux[0].length!==0) 
      {                
        setDatos(aux[0]);                                      
        if (opcionBit==='rango') {
          setListDet(aux[0])
          const auxTotal=aux[0].UsrCobro + aux[0].ExeEdad + aux[0].ExeDiscapa + aux[0].ExeNinos + aux[0].ExeOtros
          setArrGrafica1([auxTotal,aux[0].UsrCobro, aux[0].ExeEdad, aux[0].ExeDiscapa, aux[0].ExeNinos, aux[0].ExeOtros])
          setArrGrafTp([aux[0].GralDisponible,100-aux[0].GralDisponible])
        }
      }          
    }

    llamaApiCargaToken(data, url,respuesta,ErrorGeneral,setEspera,history,source);
  }
  
  const datosDispoRango=()=>{
     
    const fInicio=moment(inicio).format("YYYY-MM-DD")        
    const fFin=moment(final).format("YYYY-MM-DD")                 
    const data = qs.stringify({    
      FechaIni:fInicio+'T00:00:00' ,      
      FechaFin:fFin+'T23:59:59',            
      idDispositivo:'ifrekvjkxcdwdcwe'
    });
    const url = "/reporte-bitacora-disponibilidad-intervalo";
    function respuesta(aux) {
      if (aux[0].length!==0) 
      {   setListDispo(aux);                                      
      }          
    }

    llamaApiCargaToken(data, url,respuesta,ErrorGeneral,setEspera2,history,source);
  }

  const datosDispo=()=>{
     
    const fInicio=moment(inicio).format("YYYY-MM-DD")     
    const fLim=new Date(moment( opcionBit === 'dia' ? inicio : final ).format("MM/DD/YYYY") )
    const fLimit=moment(inicio).format("MM")                
    const data = qs.stringify({    
      Fecha:fInicio+'T00:00:00' ,      
      idDispositivo:'ifrekvjkxcdwdcwe'
    });
    const url = "/reporte-bitacora-disponibilidad";
    function respuesta(aux) {
      if (aux[0].length!==0) 
      { //const filtro=aux.filter((disp)=>moment(disp.fecha).format("YYYY-MM-DD")===fInicio ||moment(disp.fecha).format("YYYY-MM-DD")===fAntes ) 
        const filtro=aux.filter((disp)=>new Date(moment.utc(disp.fecha).format("MM/DD/YYYY") )<= fLim &&  moment.utc(disp.fecha).format("MM")===fLimit)         
        setListDispo(filtro);                                      
      }          
    }

    llamaApiCargaToken(data, url,respuesta,ErrorGeneral,setEspera2,history,source);
  }

  function verificaReporte(auxJson){
    let auxValido=false;
    if (auxJson?.terminal !== undefined && auxJson.terminal[0].paros) {
      auxValido=true;
    //console.log('no esta vacio');
    } 
    else 
    {
      auxValido=false;
      //console.log('si esta vacio');
    }
    return auxValido;
  }

  const incrementarBuscar = e =>
  { // e.preventDefault();    
      
    setBuscar(!buscar)        
      
  };//Reporte-BitacoraIntervalo240508

  const datosGeneral=(datos)=>{      
    
    return(
    <Box>    
      <Card>
    <Box display={"flex"} flexDirection={"row"}>
      <Box mx={1} mt={1} width={"33%"} >
        <Typography className={classes.title4}  variant="h5" component="h2" 
          align="center"  color={'secondary'}> 
        {datos.GralTiempoTotal }
        </Typography>                                    
        <Typography color="textSecondary" style={{fontSize:.95+'rem'}} align="center">
          Tiempo de Paros
        </Typography>
      </Box>     
      <Box mx={1} mt={1} width={"33%"} >
        <Typography className={classes.title4}  variant="h5" component="h2" 
          align="center"  color={'secondary'}> 
        {datos.GralDisponible}% 
           
        </Typography>                                    
        <Typography color="textSecondary" style={{fontSize:.95+'rem'}} align="center">
          Disponibilidad
        </Typography>
      </Box>    
      <Box mx={1} mt={1} width={"33%"} >
        <Typography className={classes.title4}  variant="h5" component="h2" 
          align="center"  color={'secondary'}> 
          {datos.Vuelta  }  
        </Typography>                                    
        <Typography color="textSecondary" style={{fontSize:.95+'rem'}} align="center">
          Vueltas
        </Typography>
      </Box>   
    </Box>
    <Box display={"flex"} flexDirection={"row"} mb={1}>
        
      <Box mx={1} mt={1} width={"33%"} >
        <Typography className={classes.title4}  variant="h5" component="h2" 
          align="center"  color={'secondary'}> 
          {datos.GralTiempo }
        </Typography>                                    
        <Typography color="textSecondary" style={{fontSize:.95+'rem'}} align="center">
            Tiempo Minimo
        </Typography>
      </Box> 
      <Box mx={1} mt={1} width={"33%"} >
        <Typography className={classes.title4}  variant="h5" component="h2" 
          align="center"  color={'secondary'}> 
          <NumberFormat value= {datos.Km  } displayType={'text'}  
            thousandSeparator={true}/>    
        </Typography>                                    
        <Typography color="textSecondary" style={{fontSize:.95+'rem'}} align="center">
          Km
        </Typography>
      </Box>     
      <Box mx={1} mt={1} width={"33%"} >
        <Typography className={classes.title4}  variant="h5" component="h2" 
          align="center"  color={'secondary'}> 
          {datos.GralTiempoMax }
        </Typography>                                    
        <Typography color="textSecondary" style={{fontSize:.95+'rem'}} align="center">
          Tiempo Maximo
        </Typography>
      </Box>         
    </Box>
   {  /* arrGrafTp.length!==0?
    <Doughnut data={dataGraficaTiempo} 
      options={optionsGrafica}
     
      width={400}
      height={380}
    />
    :null  */}
    </Card>
    </Box>
    ) 
  }

  const datosUsu=(datos)=>{    
    
    return(
    <Box mb={1} mt={1}>   
      <Card>
    <Box display={"flex"} flexDirection={"row"}>
      <Box mx={1} mt={1} width={"33%"}>
        <Typography className={classes.title4}  variant="h5" component="h2" 
          align="center"  color={'secondary'}> 
          <NumberFormat value= {datos.UsrCobro + datos.ExeEdad + datos.ExeDiscapa + datos.ExeNinos + datos.ExeOtros} displayType={'text'}  
            thousandSeparator={true}/>    
        </Typography>                                    
        <Typography color="textSecondary" style={{fontSize:.95+'rem'}} align="center">
          Total Usuarios
        </Typography>
      </Box>     
      <Box mx={1} mt={1} width={"33%"} >
        <Typography className={classes.title4}  variant="h5" component="h2" 
          align="center"  color={'secondary'}> 
          <NumberFormat value= {datos.UsrCobro} displayType={'text'}  
            thousandSeparator={true}/>    
        </Typography>                                    
        <Typography color="textSecondary" style={{fontSize:.95+'rem'}} align="center">
            Usuarios
        </Typography>
      </Box>    
      <Box mx={1} mt={1} width={"33%"} >
        <Typography className={classes.title4}  variant="h5" component="h2" 
          align="center"  color={'secondary'}> 
          <NumberFormat value= {datos.ExeEdad  } displayType={'text'}  
            thousandSeparator={true}/>    
        </Typography>                                    
        <Typography color="textSecondary" style={{fontSize:.95+'rem'}} align="center">
          3edad
        </Typography>
      </Box>   
    </Box>
    <Box display={"flex"} flexDirection={"row"} mb={1}>
        
      <Box mx={1} mt={1}  width={"33%"} >
        <Typography className={classes.title4}  variant="h5" component="h2" 
          align="center"  color={'secondary'}> 
          <NumberFormat value= {datos.ExeDiscapa } displayType={'text'}  
            thousandSeparator={true}/>    
        </Typography>                                    
        <Typography color="textSecondary" style={{fontSize:.95+'rem'}} align="center">
            Discapacidad
        </Typography>
      </Box> 
      <Box mx={1} mt={1}  width={"33%"} >
        <Typography className={classes.title4}  variant="h5" component="h2" 
          align="center"  color={'secondary'}> 
          <NumberFormat value= {datos.ExeNinos  } displayType={'text'}  
            thousandSeparator={true}/>    
        </Typography>                                    
        <Typography color="textSecondary" style={{fontSize:.95+'rem'}} align="center">
          Niños
        </Typography>
      </Box>     
      <Box mx={1} mt={1}  width={"33%"} >
        <Typography className={classes.title4}  variant="h5" component="h2" 
          align="center"  color={'secondary'}> 
          <NumberFormat value= {datos.ExeOtros } displayType={'text'}  
            thousandSeparator={true}/>    
        </Typography>                                    
        <Typography color="textSecondary" style={{fontSize:.95+'rem'}} align="center">
          Otros
        </Typography>
      </Box>         
    </Box>
    </Card> 
   {/* arrGrafica1.length!==0?
    <Bar data={dataGrafica} 
      options={optionsGrafica}
      width={400}
      height={380}
    />
    :null */}
    </Box>
    ) 
  }

  const dataGrafica = {
    labels:camposListas,
    datasets: [
      {    
      data:arrGrafica1,
      backgroundColor: colores,
      label:    ["Usuarios"],
      },         
    ]     
  };

  const dataGraficaTiempo = {
    labels:["En servicio", "Paro/Falla"],
    datasets: [{ 
      data:arrGrafTp,
      backgroundColor: [ "#5DE163", "#FF0D0D" ],
      hoverBackgroundColor: [ "#5DE163", "#FF0D0D" ],
      label: ["Disponibilidad"],
    },]     
  };

  const optionsGrafica={
    responsive: true,     
    interaction: {
      intersect: false,
    },         
  }

  const cambioDetalle = (event) => {
    setCheckDetalle( event.target.checked );
  };


  const urlReport=opcionBit==='dia'?"reports/BitacoraReport241204.mrt":"reports/Reporte-BitacoraIntervalo241204.mrt"
  return (
  <div>            
    <Box display="flex" flexDirection="row" >
      <Box display="flex" width="22rem" flexDirection="column" id='caja0'>
        <Box id="caja1" display="flex" flexDirection="row">
        <RadioBitacora opcion={opcionBit} setOpcion={setOpcionBit} opcionRef={opcionRef} />
        {opcionBit === 'rango' ?
          <FormControlLabel label="Detalle"
            control={<Checkbox checked={checkDetalle} name="checkDetalle" onChange={cambioDetalle}/>}            
          />
        :null}
        </Box>
        <Box display="flex" id="caja2"  flexDirection="row" mb={1} >        
          <Relojes2 fechas={fechas} setFechas ={setFechas } opcionBit={opcionBit} />
          <Box style={{ marginLeft:".5rem"}} display="flex" justifyContent="center">    
            <Tooltip title="Buscar reporte" >                          
              <IconButton color="inherit"  aria-label="add circle" 
                component="span" onClick={incrementarBuscar} 
              >
                <SearchIcon color="primary" style={{ fontSize: 40 }}  />
              </IconButton>            
            </Tooltip>  
          </Box>    
        </Box>
        {listEfectividad.length!==0 ? 
          <TablaEfectividad lista={listEfectividad}/>
          :null
        }
        {/* <span style={{backgroundColor:'red'}}>Dentro de la columna</span> */}
        {listDispo.length !== 0 ? 
          //<Disponib listDispo={listDispo} /> 
          <TablaBitacora lista={listDispo}/>
        :null }   
        {opcionBit === 'rango' && listDet.length!==0  &&listDispo.length!==0?
          datosUsu(listDet)
        :null}    
        {opcionBit === 'rango' && listDet.length!==0  &&listDispo.length!==0?
          datosGeneral(listDet)
        :null}    
        
      </Box>
      <Box display="flex"   >
        {opcionBit==="dia"||( checkDetalle && opcionBit==="rango") ?
        ( !espera ? 
          datos.length !== 0 ?  ( verificaReporte(datos) ?
            <Reporte urlReport={urlReport} informacion={datos}
              nombre={' Reporte Bitacora'} mostrarTB={findNumber('|54|')?true:false  }
            />
          :<span>{<ReporteSN urlReport={urlReport} informacion={datos}
              nombre={' Reporte Bitacora'} mostrarTB={findNumber('|54|')?true:false }
            />/*   */ } </span>
            ) :<Reporte urlReport={urlReport} informacion={datos}
            nombre={' Reporte Bitacora'} mostrarTB={findNumber('|54|')?true:false }
          />
          :
          <Box style={{width:'100%'}} pt={2}>
            <LinearProgress color="primary" /> 
          </Box>)
        :null
        }                
      </Box>
    </Box>                        
  </div>
  )
}

export default RepoBitacoraRango