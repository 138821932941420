import {makeStyles} from "@material-ui/core/styles";
import {useState} from "react";
import {Grid, Paper, Typography} from "@material-ui/core";
import Viewer from 'react-viewer';

const useStyles = makeStyles((theme) => ({
    imageSize: {
        width: '100%', height: '20vh'
    }, container: {
        overflowY: 'auto', maxHeight: window.innerHeight - 240, marginTop: '.5rem'
    }, content: {
        padding: '0!important', margin: 0
    }
}));

const Galeria = ({galeria}) => {    
    const classes = useStyles();
    const [index, setIndex] = useState(0);
    const [visible, setVisible] = useState(false);

    const handleViewer = (index) => {
        setVisible(true)
        setIndex(index)
    }

    const lista = () => {
        return galeria.map((element, index) => {
            
            return <Grid item xs={4}>
                <Typography>
                {element.TipoNom}
                </Typography>
            </Grid>
        })
    }

    const viewer = <Paper square>
        <Viewer
            visible={visible}
            onClose={() => {
                setVisible(false);
            }}
            images={galeria.map((element) => {
                return ({src: element.Dir + element.Archivo, alt: element.Descr})
            })}
            activeIndex={index}
            zIndex={2000}
        />
    </Paper>

    return (<>
        <Grid container spacing={1} className={classes.container}>
            {lista()}
            {/* viewer */}
        </Grid>
    </>);
};

export default Galeria;