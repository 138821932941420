import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Box,Collapse,IconButton,Paper,Table,TableBody,TableCell,LinearProgress,
        TableContainer,TableHead,TableRow,} from '@material-ui/core';
import axios from 'axios';
import qs from 'qs';
import moment from 'moment';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme)=>({
  root: {
    width: '21rem',
  },
  container: {
    //maxHeight: 550,
    maxHeight: window.innerHeight-320,
    minHeight: 180,
    maxWidth: '21rem',
   
  },
  tableCellHeader: {
    witdh: 'auto',
    backgroundColor: theme.palette.primary.main,
    color:'white'
  },
  container2: {
    maxHeight: 600,
    maxWidth: '21rem',
    //maxHeight: window.innerHeight-170,
    //minHeight: 280,
  },
  tableRow: {
    "&$selected, &$selected:hover": {
      backgroundColor: "#51ad32"
    }
  },
  tableCell: {
    "$selected &": {
      color: "white"
    }
  },
  hover: {},
  selected: {}
}));

export default function TablaEfectividad({   lista}) {
  const classes = useStyles();
  let history = useHistory();
  const source = axios.CancelToken.source();    
 
  const [selectedID, setSelectedID] = useState(null);
  //const [open, setOpen] = React.useState(false);     
  const [espera,setEspera]=useState(false);
  const [datos, setDatos] = useState([])      
  React.useEffect(()=>{ 

    //console.log(lista);
    
    setDatos(lista)
    
    return ()=> {
      source.cancel();
    }                

  },[ lista]); 

  const seleccionado=(auxId,)=>{
    setSelectedID(auxId)        
  }

  const celda = (alinear, estilo, contenido) => {
    return (
    <TableCell
      align={alinear} className={classes.tableCell}
      padding={"none"} style={estilo} 
    >
      {contenido}
    </TableCell>
    );
  };

function Row(props) {
  const { row } = props;
  
  return (
    <React.Fragment>
      <TableRow onClick={ () => {  } }
        className={classes.tableRow} selected={ selectedID === row.Id}
        classes={{ hover: classes.hover, selected: classes.selected }}        
      >                
        {celda("center",{width:65+'px',fontSize:"12px"}, row.TermNom)}                           
        {celda("right",{width:"3rem "},`${row.Paros}`)}    
        {celda("right",{width:50+'px',fontSize:"12px"},row.DParo)}      
        {celda("right",{width:'50px',fontSize:"12px"},row.DSParo)}     
        {celda("right",{width:'50px',fontSize:"12px", paddingRight:".2rem"},`${row.Efect}%`)}            
      </TableRow>
      <TableRow>        
      </TableRow>
    </React.Fragment>
    );
  }

  function tablaCompleta(auxlista){
    //console.log("entrando sin preguntar");
    return(
    <TableContainer className={classes.container}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
        {auxlista.length!=0 ?  <TableRow>            
            <TableCell className={classes.tableCellHeader} align="center" padding='none' style={{fontSize:"12px"}} >{auxlista[0].TermNom}</TableCell>
            <TableCell className={classes.tableCellHeader} align="center" padding='none' style={{fontSize:"12px"}}>{auxlista[0].Paros}</TableCell>               
            <TableCell className={classes.tableCellHeader}  align="center" padding='none' style={{fontSize:"12px"}}>{auxlista[0].DParo}</TableCell>                          
            <TableCell className={classes.tableCellHeader}  align="center" padding='none' style={{fontSize:"12px"}}>{auxlista[0].DSParo}</TableCell>                    
            <TableCell className={classes.tableCellHeader}  align="center" padding='none' style={{fontSize:"12px"}}>{auxlista[0].Efect}</TableCell>            
          </TableRow>
          :null}
          <TableRow>            
            <TableCell align="center" padding='none' style={{fontSize:"12px"}} >Terminal</TableCell>
            <TableCell align="center" padding='none' style={{fontSize:"12px"}}>Paros</TableCell>               
            <TableCell align="center" padding='none' style={{fontSize:"12px"}}>Días Paros</TableCell>                          
            <TableCell align="center" padding='none' style={{fontSize:"12px"}}>Días Sin Paros</TableCell>                    
            <TableCell align="center" padding='none' style={{fontSize:"12px"}}>Efectividad</TableCell>            
          </TableRow>
        </TableHead>
        <TableBody>
          {auxlista.length!=0 ? (auxlista.map((row) => {
            if (row.TermId!==0) {
               return <Row key={row.TermId} row={row} />  
            }
              
          })):null}
        </TableBody>
      </Table>
    </TableContainer>
   )
  }

  const tablaSinDatos=()=>{
   return(<TableContainer className={classes.container2}>
    <Table stickyHeader aria-label="sticky table">
      <TableHead>
        <TableRow>            
          <TableCell align="center"> Terminal</TableCell>            
          <TableCell align="left">Paros</TableCell>
          <TableCell align="left">Dias Paros</TableCell>                                           
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow className={classes.tableRow} >          
          <TableCell  className={classes.tableCell} align="center">Dia</TableCell>          
          <TableCell  className={classes.tableCell} align="left">Sin</TableCell>             
          <TableCell  className={classes.tableCell} align="left">Registros</TableCell>                      
        </TableRow>
      </TableBody>
    </Table>
    </TableContainer>
    ) 
  }

  
 
  const contenido2=tablaCompleta(datos) 
  const tabla = datos.length!== 0 ?contenido2:tablaSinDatos()
  //const tabla = espera|| cargaEdit ?  (<Box><LinearProgress/> </Box>) :( contenido )
 
  return (
    <Paper className={classes.root}>
      {tabla}
    </Paper>
  );
}