import {useEffect, useState,useContext} from "react";
import {useHistory} from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import {makeStyles} from "@material-ui/core/styles";
import {AuthContext} from '../context/AuthContext'
import {GeneralesContext} from '../context/GeneralesContext'
import { Box, Button, Card, CardContent, Grid,
    IconButton, LinearProgress, Paper, Tooltip,
    Typography,} from "@material-ui/core";
import TablaBecas from "./TablaBecas";
import Relojes from "./Relojes";
import axios from "axios";
import qs from "qs";
import {ErrorGeneral, llamaApiCargaToken,llamadaApiToken} from "../funciones/ClienteAxios";
import Galeria from "./Galeria";
import {AddCircleOutlined} from "@material-ui/icons";
import ModalAgregarRecorrido from "./NvoBeca";
import ModalAgregarEvidencia from "./NvaEvidencia";

const useStyles = makeStyles((theme) => ({}));

const Recorrido = () => {
    const classes = useStyles();
    const idusu = localStorage.getItem("UsuId");
    const history = useHistory();
    const source = axios.CancelToken.source();

    const [fechasSeleccionadas, setFechasSeleccionadas] = useState({
        inicio: new Date(),
        final: new Date(),
    });
    const {usuario,guardarUsuario}=useContext(AuthContext);
    const {generales, guardarGenerales} = useContext(GeneralesContext);
    const [selectedID, setSelectedID] = useState(null);
    const [recorrido, setRecorrido] = useState([]);
    const [galeria, setGaleria] = useState([]);
    const [latitud, setLatitud] = useState(0);
    const [longitud, setLongitud] = useState(0);
    const [cargando, setCargando] = useState(false);
    const [abrirModalMapa, setAbrirModalMapa] = useState(false);
    const [abrirModalAgregar, setAbrirModalAgregar] = useState(false);
    const [mensaje, setMensaje] = useState("");
    const [buscar, setBuscar] = useState(true);
    const [checked, setChecked] = useState(false);
    const [listBecas, setListBecas] = useState([]);
    const [listTipo, setListTipo] = useState([]);
    const [abrirModal, setAbrirModal] = useState(false);
    const [update, setUpdate] = useState(false);
    useEffect(() => {
        const veriSesion =  () => {            
            const usu= localStorage.getItem('UsuarioNom');
            const nvl= localStorage.getItem('Tipo');   
            if(usu === null)
            {                                
                guardarUsuario([])						
                guardarGenerales([])     
                localStorage.clear();           
                history.push("/")	
            }
            else
            {   if (usuario.length === 0) 
                {   guardarUsuario({Nombre:usu,nivel:nvl});                                      
                }                
                guardarGenerales({...generales, Titulo: 'Becas'})       
                getListTipo()                               
            }
        }
        veriSesion()
    }, []);

    useEffect(()=>{
        getListBecas()
    },[buscar])

    useEffect(() => {
        setGaleria([]);
       
        if (selectedID !== null) {
            getListDocs();
        }
    }, [selectedID,update]);

    const getListTipo = () => {      
        const data = qs.stringify({
          Usuario:idusu,
          idDispositivo:'89y7gttvjhj98h'
        });
        const url = "/registro/v1/file/tipo";
        function respuestaListTermin(aux) {
          if (aux[0].Id !== -1) 
          {                        
            setListTipo(aux);                             
          }
        }            
        llamadaApiToken(data, url, respuestaListTermin,ErrorGeneral,history,source)
    };

    const getListDocs = () => {
        const data = qs.stringify({
            Llave: selectedID,
            Usuario: idusu,
            idDispositivo: "987gyubjhkkjh",
        });
        const url = "/registro/v1/file/list";

        function respuesta(response) {
            if (response[0].Id === -1) {
                setGaleria([]);
                setMensaje("Sin fotografías.");
            } else {
                setGaleria(response);
            }
        }

        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargando, history, source);
    };

    const getListBecas = () => {
        const data = qs.stringify({
            Status:0,
            Fini:"2024-11-01T00:00:00",
            Ffin:"2024-11-21T23:59:59",
            idDispositivo: "987gyubjhkkjh",
        });
        const url = "/registro/v1/list";

        function respuesta(response) {
            if (response[0].Id === -1) {
                setListBecas([]);
                setMensaje("Sin fotografías.");
            } else {
                setListBecas(response);
            }
        }

        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargando, history, source);
    };

    const buscarDatos = () => {
        setBuscar(!buscar)
    }

    const modalAgregarEvidencia = abrirModal ? <ModalAgregarEvidencia abrirModal={abrirModal}
                                                setAbrirModal={setAbrirModal} recorrido={recorrido} 
                                                listTipo={listTipo}
                                                selectedID={selectedID} idusu={idusu}  setUpdate={setUpdate}
                                            /> : null

    const modalAgregarRecorrido = abrirModalAgregar ? <ModalAgregarRecorrido abrirModal={abrirModalAgregar}
                                                        setAbrirModal={setAbrirModalAgregar}
                                                        idusu={idusu} history={history}
                                                        source={source} latitud={latitud}
                                                        longitud={longitud} actualiza={buscarDatos}
                                                    /> : null

    const agregarDatos = () => {
        setAbrirModalAgregar(true)
    }

    const listarGaleria =
        galeria.length > 0 ? ( <Galeria galeria={galeria} />) 
        : <Paper className={classes.root}>
            <Typography variant={"h5"}>{mensaje}</Typography>
        </Paper>
        

    const handleChange = () => {
        setChecked(!checked)
    };

    const handleAbrir = () => {
        setAbrirModal(true)
    }

    const principal = () => {
        return (
            <Grid container spacing={1}>
                {modalAgregarRecorrido}
                <Grid item xs={12}>
                    <Card>
                        <CardContent style={{padding:0.4+'rem'}} >
                            <Grid container spacing={1} alignItems={'center'}>
                                <Grid item>
                                    <Tooltip title={'Buscar'}>
                                        <IconButton
                                            color="primary"
                                            aria-label="Buscar"
                                            onClick={buscarDatos}
                                        >
                                            <SearchIcon/>
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                <Grid item>
                                    <Relojes
                                        fechasSeleccionadas={fechasSeleccionadas}
                                        setFechasSeleccionadas={setFechasSeleccionadas}
                                    />
                                </Grid>
                                {/* <Grid item>
                                    <FormControlLabel
                                        value={checked}
                                        control={<Checkbox color="primary"/>}
                                        label="Buscar solo mis registros"
                                        labelPlacement="end"
                                        size={'small'}
                                        onChange={handleChange}
                                    />
                                </Grid> */}
                                 <Grid item>
                                    <Tooltip title={'Agregar Recorrido'}>
                                        <IconButton
                                            color="primary"
                                            aria-label="Agregar Recorrido"
                                            onClick={agregarDatos}
                                        >
                                            <AddCircleOutlined/>
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={8}>
                {listBecas.length!==0?
                    <TablaBecas
                        history={history} setRecorrido={setRecorrido}
                        fechasSeleccionadas={fechasSeleccionadas}
                        selectedID={selectedID} setSelectedID={setSelectedID}
                        source={source} idusu={idusu} buscar={buscar}
                        checked={checked}  buscarDatos={buscarDatos}
                        listBecas={listBecas}
                    />
                :null}
                </Grid>
                <Grid item xs={4}>
                    {modalAgregarEvidencia}
                    {recorrido.Obsrv ? 
                        cargando ? (
                            <Box mt={2} mb={2} width="80%">
                                <LinearProgress/>
                            </Box>
                        ) 
                        : (
                            <Grid container>
                            <Grid item xs={12} style={{paddingBottom: 10}}>
                                <Typography color="primary">
                                    Observación
                                </Typography>
                                <Typography>
                                    {recorrido?.Obsrv}
                                </Typography>
                            </Grid>
                            </Grid>
                        ) 
                    :null}
                    {selectedID !== null ? <Grid container>
                        <Grid item xs={12} style={{paddingBottom: 10}}>
                            <Button
                                variant="contained" color="primary"
                                startIcon={<AddCircleOutlined/>}
                                onClick={handleAbrir}
                            >
                                Subir Documentos
                            </Button>
                        </Grid>
                    </Grid> : null}
                    {cargando ? (
                        <Box pl={1} pt={5}>
                            <LinearProgress/>
                        </Box>
                    ) : (
                        listarGaleria
                    )}
                </Grid>
            </Grid>
        );
    };

    return <div>{principal()}</div>;
};

export default Recorrido;
